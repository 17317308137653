export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyD7kS8MRPi1gNAq1hC7u9STTRVQo_LHdTc",
    authDomain: "permitme-westside.firebaseapp.com",
    projectId: "permitme-westside",
    storageBucket: "permitme-westside.appspot.com",
    messagingSenderId: "557536764474",
    appId: "1:557536764474:web:27baf2bb72d13020a40b03",
    measurementId: "G-4K4NNELMPC"
  },
};

